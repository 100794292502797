<template>
    <div id="FinalStep">
        <div class="row">
            <div :class="[(simulationType == 'SIMPLE' ? 'col-lg-12 giga-simple' : 'col-lg-12 giga-complex')]">
                <div class="giga-simulation_thanks_row">
                    <div class="simulation-thanks_content">
                        <i class="thum-icon">
                            <svg width="83" height="72" viewBox="0 0 83 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M44.9886 2.27065L24.6151 22.602C23.2544 23.9599 22.4821 25.8316 22.4821 27.7766V64.4392C22.4821 68.4761 25.7919 71.7791 29.8372 71.7791H62.9349C65.8769 71.7791 68.5248 70.0175 69.7016 67.3385L81.6903 39.4103C84.7794 32.1439 79.447 24.07 71.5403 24.07H50.7623L54.256 7.26175C54.6237 5.42679 54.0721 3.55512 52.7482 2.23395C50.5784 0.105392 47.1216 0.105392 44.9886 2.27065ZM7.77204 71.7791C11.8173 71.7791 15.1271 68.4761 15.1271 64.4392V35.0798C15.1271 31.0429 11.8173 27.7399 7.77204 27.7399C3.72677 27.7399 0.416992 31.0429 0.416992 35.0798V64.4392C0.416992 68.4761 3.72677 71.7791 7.77204 71.7791Z" fill="#6CBE3E"/>
                            </svg>
                              </i>
                        <h3 v-if="isEdit">Simulation modifiée!</h3>
                        <h3 v-else>Simulation ajoutée!</h3>
                        <p>Notre équipe vérifiera vos informations et vous contactera par la suite.</p>
                        <div class="giga-button">
                            <button class="giga-blue_button no-icon" @click="$router.push({ name: 'Simulations' })">
                                Revenir vers les simulations
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      <div class="giga-fields">
        <div class="col-lg-12 mt-4">
          <div class="giga-input_field">
            <label class="input-field_label">{{$t('Ajouter un commentaire')}}</label>
            <div class="input-field_area">
              <textarea v-model="$v.simulation.comment.$model" :disabled="isEditMode"></textarea>
              <button :class="isEditMode ? 'edit-button' : 'material-symbols-outlined'" @click="saveComment" :disabled="isVIEWER">
                <svg class="tick-icon" width="19" height="19" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isEditMode">
                  <path d="M13.9199 2.61648C14.1196 2.81401 14.1196 3.14586 13.9199 3.34339L6.70203 10.6284C6.50232 10.8259 6.16748 10.8259 5.96777 10.6284L2.08007 6.05753C1.88036 5.86001 1.88036 5.52816 2.08007 5.33064C2.27978 5.13311 2.61461 5.13311 2.81432 5.33064L6.33333 9.19748L13.1862 2.98153C13.3859 2.78401 13.7207 2.78401 13.9199 2.98153Z" fill="currentColor"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 72 72" v-if="isEditMode">
                  <path class="svg-icon-path" d="M38.406 22.234l11.36 11.36L28.784 54.576l-12.876 4.307c-1.725.577-3.367-1.065-2.791-2.79l4.307-12.876L38.406 22.234zM41.234 19.406l5.234-5.234c1.562-1.562 4.095-1.562 5.657 0l5.703 5.703c1.562 1.562 1.562 4.095 0 5.657l-5.234 5.234L41.234 19.406z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { simulationService } from '@/views/Simulations/services';
import {helpers} from "@/shared/helper";

export default {
  name: 'FinalStep',
  computed: {
    ...mapGetters('simulation', {simulationType: 'getSimulationType', isEdit: 'isEdit', getSimulation: 'getSimulation',}),
    isCommentRegistered() {
      return this.getSimulation.comment !== '';
    },
    isVIEWER(){
      return helpers.isVIEWER();
    },
  },
  mounted() {
    if(this.$route.params["id"]){
        this.isEditMode = true;
      simulationService.fetchSimulationCommentById(this.getSimulation.id).then((comment) => {
        this.$v.simulation.comment.$model = comment;
      });
    }
  },

  validations: {
    simulation: {
      comment: {
        required,
      },
    },
  },
  data() {
    return {
      isEditMode: false,
      simulation: {
        comment: ''
      },
    };
  },
  methods : {
    saveComment() {
      const payload = {
        id: this.getSimulation.id,
        comment: this.$v.simulation.comment.$model,
      };
      if(!this.isEditMode){
        simulationService.saveComment(payload);
      }
      this.isEditMode = !this.isEditMode;
    }
  }
  }
</script>

